import HttpRequest from "./http_request";

class HuayProvider extends HttpRequest {
  async getHuayTypeList() {
    return this.get("/huay/types");
  }

  async getHuayTypeDetail(id) {
    return this.get(`/huay/types/${id}`)
  }

  async editHuayTypes(data) {
    return this.update(`/huay/types`, data)
  }

  async getHuayRoundListByDate(date) {
    return this.get(`/huay/round-by-date?date=${date}`)
  }

  async getHuayRoundDetail(id) {
    return this.get(`/huay/rounds/${id}`)
  }

  async editHuayRound(id, data) {
    return this.update(`/huay/rounds/${id}`, data)
  }
}

export default HuayProvider;
