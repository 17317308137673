import Vue from "vue"
import VueRouter from "vue-router"

// layout
import Main from "@/layouts/Main"

import HomeView from "../views/HomeView.vue"
import Login from "@/views/Login"
import Logout from "@/views/Logout"

import HuayRoundByDate from "@/views/setting/HuayRoundByDate"
import HuayRoundResult from "@/views/setting/HuayRoundForm"
import HuayTypes from "@/views/setting/HuayTypes"


Vue.use(VueRouter);

const routes = [{
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "*",
    name: "Root",
    component: Main,
    redirect: "/",
    children: [{
      path: "setting/huay-round-by-date",
      name: "HuayRoundByDate",
      component: HuayRoundByDate,
    },
    {
      path: "setting/huay-round-result/:id",
      name: "HuayRoundResult",
      component: HuayRoundResult,
    }, {
      path: "setting/huay-types",
      name: "HuayTypes",
      component: HuayTypes,
    }, {
      path: "",
      name: "Home",
      component: HomeView,
    }, ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login"]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem("user")

  if (authRequired && !loggedIn) {
    console.log("middleware login")
    return next("/login")
  }

  next()
})

export default router;