<template>
  <div class="w-100 text-center my-5">
    <div class="spinner-border text-primary" role="status"></div>
    <span class="sr-only">กำลังโหลด...</span>
  </div>
</template>

<script>
export default {
  name: "LoadingIcon"
}
</script>