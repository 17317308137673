<template>
  <div class="home">
    <span>Welcome</span>
  </div>
</template>

<script>

export default {
  name: "HomeView",
};
</script>
