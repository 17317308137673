import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// plugin
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import axios from "axios"
import VueAxios from "vue-axios"
import VueSweetalert2 from "vue-sweetalert2"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import isBetween from "dayjs/plugin/isBetween"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { CDataTable, CCollapse, CPagination } from "@coreui/vue"
// import VueQuillEditor from "vue-quill-editor"
import th from "dayjs/locale/th"

//helpers
import swal from "@/helpers/swal"
import validate from "@/helpers/validate"
import setup from "@/helpers/setup"
import * as input from "@/helpers/input"

// components
import Loading from "@/components/Loading"
import Breadcum from "@/components/Breadcum"

// helpers
import * as util from "@/helpers/util"

Vue.config.productionTip = false;

setup.init()

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale({
  ...th,
  weekStart: 1
})
dayjs.tz.setDefault("Asia/Bangkok")

Vue.use(BootstrapVue, IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2)
// Vue.use(VueQuillEditor)

Vue.component("bread-cum", Breadcum)
Vue.component("loading-icon", Loading)
Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("CDataTable", CDataTable)
Vue.component("CCollapse", CCollapse)
Vue.component("CPagination", CPagination)

Vue.prototype.swal = swal
Vue.prototype.focusError = validate.focusError
Vue.prototype.$date = dayjs
Vue.prototype.util = util

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {
    onlyNumber: input.onlyNumber,
    onlyNumberAndDot: input.onlyNumberAndDot
  },
}).$mount("#app");
