import AuthProvider from "@/resources/auth_provider"
import UserProvider from "@/resources/user_provider"

const user = JSON.parse(localStorage.getItem("user"))
const authService = new AuthProvider()
const userService = new UserProvider()

export const auth = {
  namespaced: true,
  state: {
    status: {
      loggedIn: user ? true : false
    },
    user: user,
    forceReload: 0
  },
  actions: {
    async login({
      commit
    }, user) {
      const res = await authService.login({
        username: user.username,
        password: user.password
      })
      if (res.error) {
        commit("loginFailure")
      } else {
        localStorage.setItem("user", JSON.stringify(res.result))
        commit("loginSuccess", res.result)
      }

      return res
    },
    async updateUserData({
      commit,
    }) {
      const res = await userService.getUserInfo()
      if (res.error) {
        console.log("err", res.error)
        return
      }
      commit("updateUserData", res.result)
    },
    logout({
      commit
    }) {
      localStorage.removeItem("user")
      commit("logout")
    },
    forceReload({
      commit
    }) {
      commit("forceReload")
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    updateUserData(state, data) {
      state.user.userData = data
    },
    forceReload(state) {
      state.forceReload++
    }
  }
}