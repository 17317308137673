<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a href="#" @click="$router.push('/')">
          <i class="fa fa-home"></i>
        </a>
      </li>
      <li
        class="breadcrumb-item"
        v-for="(item, index) in breadcrumbItems"
        :key="index"
      >
        <span v-if="typeof item == 'string'">{{ item }}</span>
        <a href="#" v-if="typeof item == 'object'" @click="item.action">{{
          item.name
        }}</a>
      </li>
    </ol>
  </nav>
</template>

<style>
.breadcrumb {
  border-bottom: 1px solid rgb(42, 45, 53) !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
}
</style>

<script>
export default {
  name: "BreadCum",
  props: ["breadcrumbItems"],
};
</script>