<template>
  <div>
    <Header />
    <div class="d-flex" style="height: calc(100vh - 56px)">
      <Sidebar />
      <div class="container-wrapper">
        <div class="container-fluid py-3">
          <router-view :key="$store.state.auth.forceReload" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: { Header, Sidebar },
  name: "DashboardPage",
  data() {
    return {};
  },
};
</script>