<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'ตั้งค่าผลหวย']" />
    <div class="card border-primary mb-3" style="width: 700px">
      <h6 class="card-header border-primary bg-primary text-white p-2">
        ตัวเลือกการค้นหา
      </h6>
      <div class="card-body">
        <div class="d-flex flex-column">
          <div class="d-flex mb-2">
            <div class="my-auto mr-2">วันที่:</div>
            <div>
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="th"
                v-model="date"
                placeholder="เลือกวัน"
                label-no-date-selected="ยังไม่เลือก"
                today-button
                reset-button
                close-button
                label-close-button="ปิด"
                label-reset-button="รีเซ็ต"
                label-today="วันนี้"
                label-today-button="วันนี้"
              ></b-form-datepicker>
            </div>
          </div>
          <!-- <div class="mb-2">
            <label for="onlyWaitingResult" class="mr-2">เฉพาะรอออกผล:</label>
            <input
              id="onlyWaitingResult"
              type="checkbox"
              class="cursor-pointer"
              v-model="onlyWaitingResult"
            />
          </div>
          <div class="mb-2">
            <label for="hideYeekee" class="mr-2">ซ่อนยี่กี:</label>
            <input
              id="hideYeekee"
              type="checkbox"
              class="cursor-pointer"
              v-model="hideYeekee"
            />
          </div> -->
          <div class="d-flex">
            <button class="btn btn-primary mr-2" @click="initData">
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        งวดหวยตามวัน
      </h5>
      <div class="card-body">
        <!-- <button
          class="btn btn-success mb-2"
          @click="$router.push('/setting/huay-round')"
        >
          เพิ่มงวดหวย
        </button> -->
        <loading-icon v-show="isLoading" />
        <div class="table-responsive" v-show="!isLoading">
          <table
            class="table table-bordered table-hover table-dark text-center"
          >
            <thead>
              <tr>
                <!-- <th>Code</th> -->
                <th>ชนิด</th>
                <th>ชื่อ</th>
                <th>ผลเช้า</th>
                <th>ผลบ่าย</th>
                <th>เวลาปิดเช้า</th>
                <th>เวลาปิดบ่าย</th>
                <th>สถานะ</th>
                <!-- <th>แก้ไขโดย</th> -->
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in filterHuayRounds()" :key="index">
                <!-- <td>{{ item.code }}</td> -->
                <td>{{ item.typeName }} ({{item.typeCode}})</td>
                <td>{{ item.name }}</td>
                <td>
                  <span v-if="item.resultMorningTop != ''">
                    บน: {{ item.resultMorningTop }} | ล่าง:
                    {{ item.resultMorningBottom }}
                  </span>
                </td>
                <td>
                  <span v-if="item.resultAfternoonTop != ''">
                    บน: {{ item.resultAfternoonTop }} | ล่าง:
                    {{ item.resultAfternoonBottom }}
                  </span>
                </td>
                <td>
                  <span v-if="item.isOpenMorning">
                    {{
                      $date(item.closeTimeMorning).format("DD-MM-YYYY HH:mm")
                    }}
                  </span>
                </td>
                <td>
                  <span v-if="item.isOpenAfternoon">
                    {{
                      $date(item.closeTimeAfternoon).format("DD-MM-YYYY HH:mm")
                    }}
                  </span>
                </td>
                <td>{{ item.status }}</td>
                <!-- <td>{{ item.updatedBy }}</td> -->
                <td>
                  <router-link
                    class="btn btn-secondary mr-1"
                    :to="`/setting/huay-round-result/${item.id}`"
                  >
                    ออกผล
                  </router-link>
                  <!-- <router-link
                    class="btn btn-info"
                    :to="`/setting/lotto-result/add/${item.id}`"
                  >
                    ออกผล
                  </router-link> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HuayProvider from "@/resources/huay_provider"
const huayService = new HuayProvider()
export default {
  name: "HuayRoundByDate",
  data() {
    return {
      isLoading: true,
      huayRounds: [],
      date: this.$date().format("YYYY-MM-DD"),
      onlyWaitingResult: true,
      hideYeekee: true,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
  },
  watch: {
    date() {
      this.initData()
    },
  },
  methods: {
    async updateOpenClose(item, status) {
      let statusTH = status == "OPEN" ? "เปิด" : "ปิด"
      let res = await this.$swal.fire({
        title: `ท่านต้องการ ${statusTH}หวย ใช่หรือไม่`,
        html: `${item.typeName} ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      })
      if (!res.isConfirmed) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "patch",
        url: "huay/round",
        data: {
          huayRoundId: item.id,
          status: status,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "สำเร็จ",
          })
          this.$store.dispatch("auth/forceReloadView")
        })
        .catch((err) => {
          console.log(err)
          this.swal.swalError()
        })
    },
    filterHuayRounds() {
      if (this.onlyWaitingResult && this.hideYeekee) {
        return this.huayRounds.filter(
          (item) => item.status == "open" //&&
          // this.$date(item.closeTime).isBefore(this.$date())
        )
      }
      // if (this.onlyWaitingResult) {
      //   return this.huayRounds.filter(
      //     (item) =>
      //       item.status == "OPEN" &&
      //       this.$date(item.closeTime).isBefore(this.$date())
      //   )
      // }
      // if (this.hideYeekee) {
      //   return this.huayRounds.filter((item) => !item.multiRoundPerDay)
      // }
      return this.huayRounds
    },
    async initData() {
      this.isLoading = true
      const res = await huayService.getHuayRoundListByDate(this.date)
      this.isLoading = false
      if (res.error) {
        this.swal.error(res.error.message)
        return
      }
      this.huayRounds = res.result.list
    },
  },
  mounted() {
    this.initData()
  },
}
</script>