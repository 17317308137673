<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'ชนิดหวย']" />
    <div class="card border-primary mb-3">
      <h5 class="card-header border-primary bg-primary text-white">
        ตั้งค่าผลหวย
      </h5>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          tag="form"
          @submit.prevent="submitForm"
          novalidate
        >
          <div
            class="form-group row"
            v-for="(huayType, i) in huayTypes"
            :key="huayType.id"
          >
            <div class="col-12">
              <h4>{{ huayType.name }}</h4>
              <div class="form-group row">
                <label
                  :for="`profitMin_${huayType.id}`"
                  class="col-12 col-sm-2"
                >
                  กำไร:
                </label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ValidationProvider rules="required|min_value:-100|max_value:100" v-slot="v">
                        <input
                          :id="`profitMin_${huayType.id}`"
                          type="number"
                          class="form-control mr-1"
                          v-model.number="huayType.profitMin"
                          placeholder="ต่ำสุด"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6">
                      <ValidationProvider
                        rules="required|numeric|min_value:-100|max_value:100"
                        v-slot="v"
                      >
                        <input
                          :id="`profitMax_${huayType.id}`"
                          type="text"
                          class="form-control mr-1"
                          v-model.number="huayType.profitMax"
                          placeholder="สูงสุด"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label
                  :for="`isProfitAll_${huayType.id}`"
                  class="col-12 col-sm-2"
                >
                  กำไรทุกเว็บ:
                </label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <b-form-radio-group
                    :id="`isProfitAll_${huayType.id}`"
                    v-model="huayType.isProfitAll"
                    :options="yesNoOptions"
                    :aria-describedby="''"
                    :name="`radio-is-profit-all-options-${huayType.id}`"
                  ></b-form-radio-group>
                </div>
              </div>

              <hr v-if="i + 1 != huayTypes.length" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-sm-10 offset-sm-2">
              <button class="btn btn-primary mr-3" type="submit">บันทึก</button>
              <button
                class="btn btn-danger"
                @click="$router.push('/setting/huay-round-by-date')"
                type="button"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import HuayProvider from "@/resources/huay_provider"
const huayService = new HuayProvider()
export default {
  data() {
    return {
      huayTypes: [],
      yesNoOptions: [
        {
          text: "ใช่",
          value: true,
        },
        {
          text: "ไม่",
          value: false,
        },
      ],
    }
  },
  mounted() {
    this.getHuayTypeList()
  },
  methods: {
    async getHuayTypeList() {
      this.swal.processing()
      const res = await huayService.getHuayTypeList()
      if (res.error) {
        await this.swal.error(res.error.message)
        return
      }
      this.huayTypes = res.result.list
      this.swal.close()
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = await huayService.editHuayTypes({
        huayTypes: this.huayTypes
      })
      if (res.error) {
        await this.swal.error(res.error.message)
        return
      }
      await this.swal.success("ตั้งค่าออกผลอัตโนมัติสำเร็จ")
      this.$store.dispatch("auth/forceReload")
    },
  },
}
</script>