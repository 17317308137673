<template>
  <nav class="bg-nav-top d-flex justify-content-between py-2 pr-2">
    <router-link class="d-flex ml-2" to="/welcome">
      <!-- <div class="border-right pr-2" style="border-color: yellow">
        <img src="~@/assets/images/logo-main.png" height="40" />
      </div> -->
      <h2 class="my-auto h4 text-white font-weight-bold" style="letter-spacing: 5px"
        >Lotto Virtual</h2
      >
    </router-link>

    <div class="d-flex">
      <span class="h5 my-auto mr-2">{{ currentTime }}</span>

      <button
        class="btn btn-primary rounded-0"
        @click="reload"
        :disabled="disabledReload"
      >
        <i class="fas fa-redo"></i>
      </button>
    </div>
  </nav>
</template>

<style>
.dropdown-header {
  background-color: #28681b;
  color: white !important;
}

.dropdown-toggle {
  border-radius: 0 !important;
}

.dropdown-menu.show {
  border-radius: 0 !important;
}

.dropdown-item {
  font-size: 0.75rem !important;
}

.bg-nav-top {
  background: linear-gradient(
    90deg,
    rgba(75, 58, 36, 1) 0%,
    rgba(117, 94, 58, 1) 29%,
    rgba(183, 150, 113, 1) 52%,
    rgba(240, 216, 170, 1) 69%,
    rgba(117, 94, 58, 1) 90%
  );
}

a.navbar-brand {
  color: white;
  font-size: 1.4rem;
}

.navbar-toggler-icon {
  color: white;
}

.navbar {
  padding: 0;
}
</style>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      currentTime: "",
      disabledReload: false,
      timeout: null,
    }
  },
  methods: {
    reload() {
      this.disabledReload = true

      this.timeout = setTimeout(() => {
        this.disabledReload = false
      }, 3000)

      this.$store.dispatch("auth/forceReload")
    },
    makeCurrentTime() {
      let date = this.$date()
      this.currentTime = `วันที่ ${date.format("DD")} ${date.format("MMMM")} ${
        parseInt(date.format("YYYY")) + 543
      } ${date.format("HH:mm:ss")}`
    },
  },
  beforeCreate: function () {
    document.body.className = ""
  },
  async mounted() {
    this.makeCurrentTime()
  },
}
</script>