import axios from "axios"
import validate from "./validate"
import router from "@/router"
import Swal from "sweetalert2"

let isDupLogin = false

function setupAxios() {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  axios.interceptors.request.use(
    function (config) {
      addHeaderAuthorization(config)
      return config
    },
    function (err) {
      return Promise.reject(err)
    }
  )
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      return await refreshToken(error)
    }
  )
}

export function addHeaderAuthorization(config) {
  let user = JSON.parse(localStorage.getItem("user"))
  if (user && user.accessToken) {
    config.headers.Authorization = `Bearer ${user.accessToken}`
  }
  return config
}

export async function refreshToken(error) {
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data.message == "missing or malformed jwt"
  ) {
    logout()
    Swal.close()
    return new Promise(() => {})
  }

  const originalRequest = error.config
  if (error.response && error.response.status === 401) {
    let user = JSON.parse(localStorage.getItem("user"))
    if (user && user.refreshToken) {
      try {
        let res = await axios.create().get("/auth/token/access", {
          headers: {
            Authorization: `Bearer ${user.refreshToken}`
          }
        })
        if (res.status === 200) {
          user.accessToken = res.data.data.accessToken
          localStorage.setItem("user", JSON.stringify(user))
          return axios(originalRequest)
        }
      } catch (e) {
        console.log(e)
      }
    }

    if (!isDupLogin) {
      isDupLogin = true
      await Swal.fire({
        text: "มีการล็อคอินจากอุปกรณ์อื่น",
        icon: "warning"
      })
      isDupLogin = false
      logout()
    }

    return new Promise(() => {})
  }

  return Promise.reject(error)
}

function logout() {
  if (
    router.currentRoute.name != "Logout" &&
    router.currentRoute.name != "Login"
  ) {
    router.push("/logout")
  }
}

export default {
  init() {
    setupAxios()
    validate.setupValidate()
  }
}