<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'ผลหวย']" />
    <div class="card border-primary mb-3">
      <h5 class="card-header border-primary bg-primary text-white">
        ตั้งค่าผลหวย
      </h5>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          tag="form"
          @submit.prevent="submitForm"
          novalidate
        >
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <span class="col-12 col-sm-2"> #ID: </span>
                <span class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  {{ form.id }}
                </span>
              </div>
              <div class="form-group row">
                <label for="" class="col-12 col-sm-2">
                  ชื่อ:
                </label>
                <span class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  {{ form.name }}
                </span>
              </div>
              <div class="form-group row" v-if="form.isOpenMorning">
                <label for="resultMorningTop" class="col-12 col-sm-2">
                  ผลเช้า:
                </label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ValidationProvider
                        rules="required|numeric|min:3|max:3"
                        v-slot="v"
                      >
                        <input
                          id="resultMorningTop"
                          type="text"
                          class="form-control mr-1"
                          v-model="form.resultMorningTop"
                          placeholder="บน"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6">
                      <ValidationProvider
                        rules="required|numeric|min:2|max:2"
                        v-slot="v"
                      >
                        <input
                          id="resultMorningBottom"
                          type="text"
                          class="form-control mr-1"
                          v-model="form.resultMorningBottom"
                          placeholder="ล่าง"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row" v-if="form.isOpenAfternoon">
                <label for="resultAfternoonTop" class="col-12 col-sm-2">
                  ผลบ่าย:
                </label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ValidationProvider
                        rules="required|numeric|min:3|max:3"
                        v-slot="v"
                      >
                        <input
                          id="resultAfternoonTop"
                          type="text"
                          class="form-control mr-1"
                          v-model="form.resultAfternoonTop"
                          placeholder="บน"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6">
                      <ValidationProvider
                        rules="required|numeric|min:2|max:2"
                        v-slot="v"
                      >
                        <input
                          id="resultAfternoonBottom"
                          type="text"
                          class="form-control mr-1"
                          v-model="form.resultAfternoonBottom"
                          placeholder="ล่าง"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group row">
                <label class="col-12 col-sm-2">สถานะ:</label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <select v-model="form.status" class="form-control">
                      <option value="open">เปิด</option>
                      <option value="close">ปิด</option>
                    </select>
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div> -->
              <div class="form-group row">
                <div class="col-12 col-sm-10 offset-sm-2">
                  <button class="btn btn-primary mr-3" type="submit">
                    บันทึก
                  </button>
                  <button
                    class="btn btn-danger"
                    @click="$router.push('/setting/huay-round-by-date')"
                    type="button"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import HuayProvider from "@/resources/huay_provider"
const huayService = new HuayProvider()
export default {
  data() {
    return {
      id: null,
      form: {
        name: "",
        isOpenMorning: false,
        isOpenAfternoon: false,
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getHuayRoundDetail()
  },
  methods: {
    async getHuayRoundDetail() {
      this.swal.processing()
      const res = await huayService.getHuayRoundDetail(this.id)
      if (res.error) {
        await this.swal.error(res.error.message)
        this.$router.push("/setting/huay-round-by-date")
        return
      }
      this.form = res.result
      this.swal.close()
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = await huayService.editHuayRound(this.id, this.form)
      if (res.error) {
        await this.swal.error(res.error.message)
        return
      }
      await this.swal.success("ตั้งค่าผลหวยสำเร็จ")
      this.$router.push("/setting/huay-round-by-date")
    },
  },
}
</script>