<template>
  <div class="d-flex flex-column justify-content-center">
    <!-- <img
      src="~@/assets/logo.png"
      width="350"
      class="mx-auto"
      style="margin-top: 6rem"
    /> -->
    <div
      class="card mt-4 mx-auto"
      style="width: 300px; background-color: rgb(0, 0, 0, 0.5) !important"
    >
      <div class="card-body d-flex flex-column px-4 py-5">
        <h2 class="text-center mb-4 font-weight-bold">Lotto Virtual</h2>
        <form @submit.prevent="handleLogin">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-user-alt mx-auto"></i>
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Username"
              v-model="user.username"
              required
            />
          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-unlock-alt mx-auto"></i>
              </div>
            </div>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="user.password"
              required
            />
          </div>
          <button type="submit" class="btn btn-login text-white w-100">
            เข้าสู่ระบบ
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
body.login {
  background: url("~@/assets/images/bg-login.jpg") no-repeat bottom center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.btn-login {
  background: linear-gradient(
    90deg,
    rgba(178, 47, 22, 1) 9%,
    rgba(241, 90, 34, 1) 34%,
    rgba(244, 147, 33, 1) 70%,
    rgba(241, 90, 34, 1) 91%
  );
}
</style>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      user: {},
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  beforeCreate: function () {
    document.body.className = "login"
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/welcome")
    }
  },
  methods: {
    async handleLogin() {
      this.swal.processing()
      const res = await this.$store.dispatch("auth/login", this.user)
      if (res.error) {
        this.$swal({
          icon: "error",
          title: "Login ไม่สำเร็จ",
          text: res.error.message,
        })
        return
      }
      this.swal.close()
      this.$router.push("/")
    },
  },
}
</script>